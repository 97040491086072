var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"product-details-section pa-md-0"},[_c('v-row',{staticClass:"title-row mb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasModel)?[_c('h1',{staticClass:"dining-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]:[_c('v-skeleton-loader',{staticClass:"pl-3",attrs:{"max-width":"550","type":"text"}})]],2)],1),(_vm.rating)?[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-start mb-3 mb-md-6",attrs:{"cols":"auto"}},[_c('DDRating',{attrs:{"ratings":_vm.rating}}),_c('span',{staticClass:"rating-text"},[_vm._v(_vm._s(_vm.rating))]),_c('span',{staticClass:"pl-1 reviews-total"},[_vm._v(" "+_vm._s(_vm.totalRatings)+" ")])],1)],1)]:_vm._e(),_c('div',{staticClass:"page-navigation-sticky mb-5 mb-md-6",class:{
      'push-top': _vm.$vuetify.breakpoint.lgAndUp || _vm.selectedContextFlight,
    }},[_c('CategoryMenuBar',{attrs:{"menuItems":_vm.menuNames,"activeTabId":_vm.activeTabId},on:{"click":_vm.scrollToMenu}})],1),(_vm.hasModel)?[_c('v-row',{staticClass:"wrapper",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-md-12 pr-0",attrs:{"md":"8","col":"12"}},[(_vm.diningHighlights.length)?[_c('DDHighlights',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"highlights-id",attrs:{"highlights":_vm.diningHighlights}})]:_vm._e(),(_vm.isLoading || _vm.description)?[_c('div',{staticClass:"description-section size16-weight400 mb-8"},[(_vm.description)?_c('glx-text-truncate',{attrs:{"text":_vm.description}}):_vm._e(),(_vm.isLoading)?[_c('v-skeleton-loader',{staticClass:"pt-3",attrs:{"max-width":"500","type":"text"}}),_c('v-skeleton-loader',{staticClass:"pb-3",attrs:{"max-width":"200","type":"text"}})]:_vm._e()],2)]:_vm._e(),(_vm.hasModel)?[_c('DDOverview',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"overview-id",staticClass:"mb-sm-8 mb-4",attrs:{"model":_vm.model,"operatingHoursList":_vm.operatingHoursList,"isLoading":_vm.isLoading}})]:_vm._e(),(
            _vm.hasModel &&
              _vm.model.safetyMeasures &&
              _vm.model.safetyMeasures.length > 0
          )?[_c('DDHealthSafety',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"safetyMeasures-id",attrs:{"hasModel":_vm.hasModel,"model":_vm.model}})]:_vm._e(),(_vm.images)?[_c('DiningDetailsCarousel',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"photos-id",staticClass:"mb-sm-8 mb-4",attrs:{"imageUrls":_vm.images}})]:_vm._e(),(_vm.shouldShowMap && _vm.diningAddress)?[_c('DDLocation',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.onIntersect,
              options: {
                threshold: _vm.intersectThreshold,
                rootMargin: _vm.intersectRootMargin,
              },
            }),expression:"{\n              handler: onIntersect,\n              options: {\n                threshold: intersectThreshold,\n                rootMargin: intersectRootMargin,\n              },\n            }"}],ref:"location-id",attrs:{"data-cy":"dining-details-locations-map","diningLocation":_vm.diningLocation,"model":_vm.model,"hasModel":_vm.hasModel}})]:_vm._e()],2),(_vm.$vuetify.breakpoint.mdAndUp)?[_c('v-col',{staticClass:"pl-0 pl-md-4 mt-4",attrs:{"cols":"12","md":"4"}},[_c('DiningCtaCardContainer',{staticClass:"sticky",attrs:{"model":_vm.model,"isLoading":_vm.isLoading}})],1)]:_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }