<template>
  <v-container class="pa-0 pb-5 location">
    <v-row no-gutters>
      <v-col cols="12">
        <DDSectionTitle
          data-cy="dining-details-overview"
          title="Location"
          class="mb-5"
        />

        <LMap
          :zoom="15"
          :options="{
            dragging: false,
            doubleClickZoom: false,
            scrollWheelZoom: false,
          }"
          style="width: 100%"
          :style="
            $vuetify.breakpoint.mdAndUp ? 'height: 360px' : 'height: 220px'
          "
          :center="diningLocation"
        >
          <LTileLayer :url="letleafMapUrl" subdomains="abc" />
          <LMarker :lat-lng="diningLocation">
            <LIcon :icon-url="diningLocationIcon" />
          </LMarker>
          <LMarker :lat-lng="userLocation">
            <LIcon :icon-url="userLocationIcon" />
          </LMarker>
        </LMap>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import DDSectionTitle from './DDSectionTitle';

export default {
  name: 'DDLocation',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    DDSectionTitle,
  },

  props: {
    diningLocation: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: null,
    },
    hasModel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userLocationIcon: require('@/assets/user-location.svg'),
      diningLocationIcon: require('@/assets/icon-marker.svg'),
    };
  },

  computed: {
    letleafMapUrl() {
      return 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
    },

    userLocation() {
      if (!this.hasModel) return [0, 0];
      else if (this.model.geoLocation) {
        const latitude = this.model.geoLocation.latitude;
        const longitude = this.model.geoLocation.longitude;
        return [latitude, longitude];
      }
      return [this.diningLocation[0], this.diningLocation[1]];
    },
  },
};
</script>

<style scoped lang="scss">
.section-title {
  color: var(--v-grey9-base);
  position: relative;
  padding-top: 10px;
}

.leaflet-container {
  z-index: 1;
}
</style>
