<template>
  <v-container class="pa-0 photos">
    <v-row no-gutters>
      <v-col cols="12">
        <DDSectionTitle
          class="mb-5"
          data-cy="product-gallery-overview"
          :title="$t('dining.photos')"
        />
        <div class="carousel">
          <carousel
            class="mx-xs-0 mx-sm-3 mx-md-0"
            :scrollPerPage="$vuetify.breakpoint.lgAndUp"
            :perPage="carouselSlideSize - 0.6"
            :navigationEnabled="false"
            :paginationEnabled="false"
            paginationPosition="bottom-overlay"
            :touchDrag="true"
            :mouseDrag="true"
            @pageChange="getAdvance()"
            ref="carousel"
          >
            <slide
              class="pr-4"
              v-for="(imageUrl, index) in imageUrls"
              :key="index"
              @slide-click="onCarouselImageClick(index)"
            >
              <v-card :elevation="2">
                <v-img
                  :alt="imageUrl.imageOwner.title"
                  class="white--text align-end"
                  :src="imageUrl.url"
                  :max-height="maxHeight"
                  :aspect-ratio="dynamicAspectRatio"
                  data-cy="carousel-image"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary lighten-1"
                      />
                    </v-row>
                  </template>
                </v-img>
              </v-card>
              <p class="image-title">
                {{ $t('dining.photo_by') }}
                <b>{{ imageUrl.imageOwner.title }}</b>
              </p>
            </slide>
          </carousel>
          <template v-if="$vuetify.breakpoint.mdAndUp && canAdvanceForward">
            <div class="carousel-right-blur"></div>
          </template>

          <template v-if="$vuetify.breakpoint.mdAndUp && canAdvanceBackward">
            <div class="carousel-left-blur"></div>
          </template>

          <vue-gallery
            :images="imageLinks"
            :index="clickedImageIndex"
            @close="clickedImageIndex = null"
          />
        </div>
        <div class="pt-sm-10 pt-6">
          <v-divider />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import VueGallery from 'vue-gallery';
import DDSectionTitle from './DiningDetailsSections/DDSectionTitle';

export default {
  name: 'DiningDetailsCarousel',
  components: {
    Carousel,
    Slide,
    VueGallery,
    DDSectionTitle,
  },
  props: {
    imageUrls: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      clickedImageIndex: null,
      canAdvanceForward: true,
      canAdvanceBackward: false,
    };
  },

  computed: {
    imageLinks() {
      return this.imageUrls.map((img) => img.url);
    },

    carouselSlideSize() {
      let pageSize = 3;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 2;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        pageSize = 3;
      }
      return this.imageUrls.length > pageSize
        ? pageSize
        : this.imageUrls.length;
    },

    isSingleImageSize() {
      return this.carouselSlideSize === 1;
    },

    dynamicAspectRatio() {
      let ratio = 1.4;
      if (this.$vuetify.breakpoint.lgAndUp && this.carouselSlideSize < 3) {
        ratio = this.carouselSlideSize === 2 ? 4 / 3 : 16 / 9;
      }
      return ratio;
    },
  },

  methods: {
    getAdvance() {
      this.canAdvanceForward =
        this.$refs && this.$refs.carousel
          ? this.$refs.carousel.canAdvanceForward
          : false;
      this.canAdvanceBackward =
        this.$refs && this.$refs.carousel
          ? this.$refs.carousel.canAdvanceBackward
          : false;
    },

    onCarouselImageClick(imageIndex) {
      this.clickedImageIndex = imageIndex;
    },
  },

  mounted() {
    document.querySelectorAll('h3.title').forEach((elem) => elem.remove());
  },
};
</script>

<style lang="scss" scoped>
button.VueCarousel-navigation-button:focus {
  outline: none !important;
}

button.VueCarousel-dot:focus {
  outline: none !important;
}

.image-title {
  width: 100%;
  text-align: right;
  color: #05090f;
  margin: 9px 0 0;
  @include font-size(12, 150);
}

.carousel {
  position: relative;
}

.carousel-right-blur {
  position: absolute;
  top: 0;
  right: -1px;
  height: 200px;
  width: 56px;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.carousel-left-blur {
  position: absolute;
  top: 0;
  left: -1px;
  height: 200px;
  width: 56px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

::v-deep .blueimp-gallery {
  > .title,
  > .description {
    display: none !important;
  }
}
</style>
