<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="error != null">
          <ProductNotFound :productName="'dining'" />
        </template>
        <template v-else>
          <v-container class="product-page px-0 px-md-3">
            <v-row no-gutters>
              <v-col cols="12">
                <vue-headful :title="pageTitle" />

                <template v-if="error !== null">
                  <v-banner single-line :sticky="true" class="error-banner">
                    <v-avatar slot="icon" color="error" size="40">
                      <v-icon icon="mdi-lock" color="white">mdi-alert</v-icon>
                    </v-avatar>

                    <template v-if="error.message">
                      <div class="title">
                        {{ error.message }}
                      </div>
                    </template>

                    <template v-slot:actions>
                      <v-btn text color="deep-purple accent-4" @click="refresh">
                        {{ $t('refresh') }}
                      </v-btn>
                    </template>
                  </v-banner>
                </template>

                <router-view class="product-route-port"></router-view>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiningDetailsMixins from '@/modules/product/dining/mixins/DiningDetailsMixins';
import vueHeadful from 'vue-headful';
import ProductNotFound from '@/modules/exception/ProductNotFound';

export default {
  name: 'DiningProduct',
  mixins: [DiningDetailsMixins],
  components: { vueHeadful, ProductNotFound },
  props: {
    lng: [Number, String],
    lat: [Number, String],
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },

  created() {
    this.registerStore();
    this.loadDiningDetails({ lng: this.lng, lat: this.lat });
  },

  methods: {
    refresh() {
      this.loadDiningDetails({ lng: this.lng, lat: this.lat });
    },

    goToPrevPage() {
      if (this.$route.name === 'dining-results') {
        this.$router.push({ name: 'home' });
      } else {
        if (!this.fromRoute.name) {
          this.$router.push('/dining');
        } else {
          this.$router.back();
        }
      }
    },
  },

  computed: {
    error() {
      return this.diningDetails.error;
    },

    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },

    pageTitle() {
      if (this.hasDiningDetails) {
        return `${this.diningDetails.value.title} | ${this.partnerName} | Guestlogix`;
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-banner {
  background: transparent;
  z-index: 999;
}

.product-page {
  margin-bottom: 40px;
}

.slide-in-enter-active {
  animation: slide-up 1.5s;
}

.slide-in-leave-active {
  animation: slide-up 1.5s reverse;
}

.fade-in-enter-active {
  animation: fade-in 1.5s;
}

.fade-in-leave-active {
  animation: fade-in 0.5s reverse;
}

@keyframes slide-up {
  0% {
    bottom: -100px;
  }
  50% {
    bottom: -30px;
  }
  100% {
    bottom: 0px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    height: 0%;
  }
  1% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.product-route-port {
  &,
  div {
    z-index: 2;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
</style>
