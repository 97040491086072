<template>
  <v-rating
    v-model="ratings"
    dense
    half-increments
    readonly
    size="35"
    class="mr-2 d-flex justify-start"
  >
    <template v-slot:item="props">
      <template v-if="props.isFilled">
        <v-img :src="fullStar" class="mr-1" alt="full star" />
      </template>
      <template v-else-if="props.isHalfFilled">
        <v-img :src="halfStar" class="mr-1" alt="half star" />
      </template>
      <template v-else>
        <v-img :src="emptyStar" alt="empty star" />
      </template>
    </template>
  </v-rating>
</template>

<script>
export default {
  name: 'DiningDetailsRating',
  props: {
    ratings: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      fullStar: require('@/assets/full-star.svg'),
      halfStar: require('@/assets/half-star.svg'),
      emptyStar: require('@/assets/empty-star.svg'),
    };
  },
};
</script>
