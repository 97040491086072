<script>
import DiningProductMixins from '@/modules/product/dining/mixins/DiningProductMixins';

export default {
  name: 'DiningDetailsMixins',
  mixins: [DiningProductMixins],
  methods: {
    async loadDiningDetails({ lng, lat }) {
      await this.$store.dispatch(`${this.storeModuleName}/loadDetails`, {
        productId: this.productId,
        latitude: lat,
        longitude: lng,
      });
      if (this.hasDiningDetailsError) {
        this.$router.replace({
          name: 'product-not-found',
          query: { link: 'dining-landing', name: 'dining' },
        });
      }
    },
  },
  computed: {
    hasDiningDetails() {
      return this.$store.getters[`${this.storeModuleName}/hasDiningDetails`];
    },
    diningDetails() {
      return this.$store.state[`${this.storeModuleName}`].diningDetails;
    },
    hasDiningDetailsError() {
      return !!this.diningDetails && this.diningDetails.error !== null;
    },
  },
};
</script>
