<template>
  <v-container class="dining-details px-0 mt-n6 mt-sm-0">
    <v-row no-gutters>
      <v-col cols="12">
        <FlightAlert ref="FlightAlert" isDetailsPage :link-text="linkText" />

        <template v-if="imageUrls.length">
          <ImageCarousel
            :imageUrls="imageUrls"
            :imageLimitInSingleImageMode="1"
            :maxHeight="360"
            class="mb-3 mb-md-9"
          />
        </template>

        <DiningDetailsSection
          :model="model"
          :isLoading="isLoading"
          :error="error"
        />

        <template v-if="$vuetify.breakpoint.smAndDown">
          <div class="wrapper-cta">
            <DiningCtaCardContainer :model="model" :isLoading="isLoading" />
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiningDetailsSection from './DiningDetailsSection';
import ImageCarousel from '@/modules/product/features/Details/ImageCarousel';
import DiningCtaCardContainer from '@/modules/product/dining/features/Details/DiningCtaCardContainer';
import FlightAlert from '@/core/components/FlightAlert';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'DiningDetails',
  mixins: [SelectedContextFlightMixins],
  components: {
    DiningDetailsSection,
    DiningCtaCardContainer,
    ImageCarousel,
    FlightAlert,
  },

  props: {
    model: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    hasModel() {
      return this.model !== null && this.model !== undefined;
    },

    imageUrls() {
      if (this.hasModel && this.model.imageUrls) {
        return this.model.imageUrls;
      }
      return [require('@/assets/placeholder-image.jpg')];
    },

    linkText() {
      return this.$t('dining.link_text', {
        destination: this.selectedContextFlight?.destination.city,
      });
    },
  },

  watch: {
    selectedContextFlight(val, oldVal) {
      if (!!val && val.destination?.city !== oldVal.destination?.city) {
        this.$refs.FlightAlert.showAlert('dining-results');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-cta {
  max-width: 100%;
  position: fixed;
  bottom: -1px;
  width: 100%;
  background: #fff;
  z-index: 9999;
}
</style>
