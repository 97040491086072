<template>
  <v-container class="pa-0 overview dining-details-overview">
    <v-row no-gutters>
      <v-col cols="12">
        <DDSectionTitle
          :title="$t('product.menu_names.overview')"
          class="mb-5 mb-md-4"
        />

        <template v-if="cuisine">
          <div class="cuisine-section mb-4 mb-md-6">
            <h3 class="cuisine-section-title mb-2">
              {{ $t('dining.cuisine') }}
            </h3>
            <div class="cuisine-section-text">
              <template v-if="cuisine">
                <glx-text-truncate :text="cuisine" />
              </template>

              <template v-if="isLoading">
                <v-skeleton-loader class="pt-3" max-width="500" type="text" />
                <v-skeleton-loader class="pb-3" max-width="200" type="text" />
              </template>
            </div>
          </div>
        </template>

        <template v-if="attention">
          <div class="mb-4 mb-md-6">
            <h3 class="cuisine-section-title mb-4">
              {{ $t('dining.attention') }}
            </h3>
            <ul class="cuisine-section-text mb-6 pl-7">
              <li class="mb-3" v-for="(item, i) in attention" :key="i">
                {{ item }}
              </li>

              <template v-if="isLoading">
                <v-skeleton-loader class="pt-3" max-width="500" type="text" />
                <v-skeleton-loader class="pb-3" max-width="200" type="text" />
              </template>
            </ul>
          </div>
        </template>

        <template v-if="operatingHoursList">
          <div data-cy="product-details-locations-key-infos">
            <h3 class="cuisine-section-title mb-2">{{ $t('dining.hours') }}</h3>
            <div class="cuisine-section-text">
              <v-alert class="pa-3 pl-4 mb-2" outlined text color="#DC790C">
                <span class="covid-alert">
                  {{ $t('dining.covid_alert') }}
                </span>
              </v-alert>
              <p
                v-for="(hours, i) in operatingHoursListWithTitle"
                :key="i"
                v-html="hours"
                class="mb-0"
              />

              <template v-if="isLoading">
                <v-skeleton-loader
                  class="pb-2 pt-1"
                  max-width="300"
                  type="text"
                />
              </template>
            </div>
          </div>
        </template>

        <div class="pt-sm-10 pt-6">
          <v-divider />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DDSectionTitle from './DDSectionTitle';

export default {
  name: 'DDOverview',
  components: { DDSectionTitle },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    operatingHoursList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    operatingHoursListWithTitle() {
      return this.operatingHoursList.map((item) => {
        return `<span>${item.slice(0, item.indexOf(':'))}</span>${item.slice(
          item.indexOf(':')
        )}`;
      });
    },

    hasModel() {
      return this.model !== null && this.model !== undefined;
    },

    cuisine() {
      if (this.hasModel && this.model.keywords && this.model.keywords.tags) {
        return this.model.keywords.tags
          .map((item) => {
            return `${item[0].toUpperCase()}${item.slice(1)}`;
          })
          .join(', ');
      }
      return null;
    },

    attention() {
      if (
        this.hasModel &&
        this.model.keywords &&
        this.model.keywords.features
      ) {
        return this.model.keywords.features.join(', ');
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.covid-alert {
  color: black;
}

.cuisine-section {
  &-title {
    @include font-size(16, 150, 500);
  }

  &-text {
    @include font-size(16, 150, 400);
  }
}
</style>
