<template>
  <h2 class="section-title">
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'DDSectionTitle',
  props: {
    title: {
      type: String,
      default: 'Section title',
    },
  },
};
</script>

<style scoped lang="scss">
.section-title {
  color: var(--v-grey9-base);
  position: relative;
  padding-top: 10px;
  @include font-size(20, 120, 500);

  @media (min-width: map-get($grid-breakpoints, md)) {
    @include font-size(24, 150, 500);
  }
}
</style>
