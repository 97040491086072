<template>
  <DiningDetails
    :model="diningDetails.value"
    :isLoading="diningDetails.isLoading"
  />
</template>

<script>
import DiningDetailsMixins from '@/modules/product/dining/mixins/DiningDetailsMixins';
import DiningDetails from '@/modules/product/dining/features/Details/DiningDetails';

export default {
  name: 'DiningDetailsPage',
  components: { DiningDetails },
  mixins: [DiningDetailsMixins],
};
</script>
