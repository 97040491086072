<template>
  <div class="options-card_wrapper">
    <div class="options-card_button py-2 px-5 d-sm-none" @click="show = true">
      {{ $t('dining.view_contact') }}
    </div>

    <template v-if="$vuetify.breakpoint.smAndDown">
      <OptionsDiningCtaCardModal
        v-model="show"
        :loading="isLoading"
        :websiteUrl="websiteUrl"
        :phoneNumber="phoneNumber"
        :address="address"
      />
    </template>

    <!-- TODO not used block because we use modal -->
    <template v-else>
      <OptionsDiningCtaCard
        :loading="isLoading"
        :websiteUrl="websiteUrl"
        :phoneNumber="phoneNumber"
        :address="address"
      />
    </template>
    <!-- TODO end -->
  </div>
</template>

<script>
import OptionsDiningCtaCard from '@/modules/product/dining/features/OptionsDiningCtaCard.vue';
import OptionsDiningCtaCardModal from '@/modules/product/dining/features/OptionsDiningCtaCardModal';

export default {
  name: 'DiningCtaCardContainer',
  components: { OptionsDiningCtaCard, OptionsDiningCtaCardModal },
  props: {
    model: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  computed: {
    websiteUrl() {
      if (this.model && this.model.contact && this.model.contact.website) {
        return this.model.contact.website;
      }
      return null;
    },

    phoneNumber() {
      if (this.model && this.model.contact && this.model.contact.phones) {
        return this.model.contact.phones.join(', ');
      }
      return null;
    },

    address() {
      if (this.model && this.model.contact) {
        return this.model.contact.address;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.options-card {
  &_wrapper {
    width: 100%;
    background: #fff;
  }

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background: var(--v-primary-base);
    height: 58px;
    color: #fff;
    cursor: pointer;
    @include font-size(16, 150, 700);
  }
}
</style>
