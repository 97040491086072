<template>
  <v-container class="product-details-section pa-md-0">
    <v-row no-gutters class="title-row mb-3">
      <v-col cols="12">
        <template v-if="hasModel">
          <h1 class="dining-title">
            {{ title }}
          </h1>
        </template>

        <template v-else>
          <v-skeleton-loader class="pl-3" max-width="550" type="text" />
        </template>
      </v-col>
    </v-row>

    <template v-if="rating">
      <v-row no-gutters>
        <v-col cols="auto" class="d-flex justify-start mb-3 mb-md-6">
          <DDRating :ratings="rating" />
          <span class="rating-text">{{ rating }}</span>
          <span class="pl-1 reviews-total">
            {{ totalRatings }}
          </span>
        </v-col>
      </v-row>
    </template>
    <div
      class="page-navigation-sticky mb-5 mb-md-6"
      :class="{
        'push-top': $vuetify.breakpoint.lgAndUp || selectedContextFlight,
      }"
    >
      <CategoryMenuBar
        @click="scrollToMenu"
        :menuItems="menuNames"
        :activeTabId="activeTabId"
      />
    </div>
    <template v-if="hasModel">
      <v-row no-gutters class="wrapper">
        <v-col md="8" col="12" class="pr-md-12 pr-0">
          <template v-if="diningHighlights.length">
            <DDHighlights
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              :highlights="diningHighlights"
              ref="highlights-id"
            />
          </template>

          <template v-if="isLoading || description">
            <div class="description-section size16-weight400 mb-8">
              <glx-text-truncate v-if="description" :text="description" />

              <template v-if="isLoading">
                <v-skeleton-loader class="pt-3" max-width="500" type="text" />
                <v-skeleton-loader class="pb-3" max-width="200" type="text" />
              </template>
            </div>
          </template>

          <template v-if="hasModel">
            <DDOverview
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              class="mb-sm-8 mb-4"
              :model="model"
              :operatingHoursList="operatingHoursList"
              :isLoading="isLoading"
              ref="overview-id"
            />
          </template>

          <template
            v-if="
              hasModel &&
                model.safetyMeasures &&
                model.safetyMeasures.length > 0
            "
          >
            <DDHealthSafety
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              :hasModel="hasModel"
              :model="model"
              ref="safetyMeasures-id"
            />
          </template>

          <template v-if="images">
            <DiningDetailsCarousel
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              ref="photos-id"
              class="mb-sm-8 mb-4"
              :imageUrls="images"
            />
          </template>

          <template v-if="shouldShowMap && diningAddress">
            <DDLocation
              v-intersect="{
                handler: onIntersect,
                options: {
                  threshold: intersectThreshold,
                  rootMargin: intersectRootMargin,
                },
              }"
              data-cy="dining-details-locations-map"
              ref="location-id"
              :diningLocation="diningLocation"
              :model="model"
              :hasModel="hasModel"
            />
          </template>
        </v-col>

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-col cols="12" md="4" class="pl-0 pl-md-4 mt-4">
            <DiningCtaCardContainer
              class="sticky"
              :model="model"
              :isLoading="isLoading"
            />
          </v-col>
        </template>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import DiningCtaCardContainer from './DiningCtaCardContainer';
import DDRating from './DiningDetailsSections/DDRating';
import DDLocation from './DiningDetailsSections/DDLocation';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import DiningDetailsCarousel from './DiningDetailsCarousel';
import DDHighlights from './DiningDetailsSections/DDHighlights';
import DDOverview from './DiningDetailsSections/DDOverview';
import DDHealthSafety from '@/modules/product/dining/features/Details/DiningDetailsSections/DDHealthSafety';
import CategoryMenuBar from '@/core/components/CategoryMenuBar';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';

export default {
  name: 'DiningDetailsSection',
  mixins: [GoogleTagManagerMixins, FlightsMixins],
  components: {
    DiningDetailsCarousel,
    DiningCtaCardContainer,
    DDRating,
    DDHighlights,
    DDHealthSafety,
    DDOverview,
    CategoryMenuBar,
    DDLocation,
  },

  props: {
    model: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      intersectThreshold: [0, 0.2],
      intersectRootMargin: '-235px 0px 0px 0px',
      highlightsActive: false,
      overviewActive: false,
      healthActive: false,
      photosActive: false,
      locationActive: false,
      activeBlocks: [],
    };
  },

  created() {
    if (this.hasModel) {
      this.triggerDetailsViewAnalytics();
    }
  },

  computed: {
    menuNames() {
      let array = [
        {
          id: 'highlights-id',
          title: this.$t('product.menu_names.highlights'),
          isShow: !!this.diningHighlights.length,
          isActive: this.highlightsActive,
        },
        {
          id: 'overview-id',
          title: this.$t('product.menu_names.overview'),
          isShow: this.hasModel,
          isActive: this.overviewActive,
        },
        {
          id: 'safetyMeasures-id',
          title: this.$t('product.menu_names.health_safety'),
          isShow:
            this.hasModel &&
            this.model.safetyMeasures &&
            this.model.safetyMeasures.length > 0,
          isActive: this.healthActive,
        },
        {
          id: 'photos-id',
          title: this.$t('dining.photos'),
          isShow: !!this.images,
          isActive: this.photosActive,
        },
        {
          id: 'location-id',
          title: this.$t('location_text'),
          isShow: !!this.diningAddress,
          isActive: this.locationActive,
        },
      ];

      return array.filter((el) => el.isShow === true);
    },

    diningAddress() {
      return this.hasModel && this.hasLocations
        ? this.model.locations[0].address
        : null;
    },

    hasLocations() {
      return this.model.locations && this.model.locations.length > 0;
    },

    diningLocation() {
      if (!this.hasModel) return [0, 0];
      else if (this.model.locations.length > 0) {
        const latitude = this.model.locations[0].latitude;
        const longitude = this.model.locations[0].longitude;
        return [latitude, longitude];
      }
      return [0, 0];
    },

    shouldShowMap() {
      const [lng, lat] = this.diningLocation;
      return lng !== 0 && lat !== 0;
    },

    images() {
      return this.hasModel ? this.model.gallery : null;
    },

    hasModel() {
      return this.model !== null && this.model !== undefined;
    },

    title() {
      return this.hasModel ? this.model.title : 'Lorem ipsum dolor sit amet';
    },

    rating() {
      return this.hasModel ? this.model.rating.averageRating : 0;
    },

    totalRatings() {
      return this.$t('dining.ratings', {
        numbers: this.hasModel ? this.model.rating?.totalNumRatings : 0,
      });
    },

    highlightsInfo() {
      return {
        PriceScale: {
          icon: 'price',
          title: this.$t('dining.price', {
            PriceScale: this.model.highlights.PriceScale,
          }),
        },
        TypeOfPlace: {
          icon: 'restaurant',
          title: this.$t('dining.dining_type', {
            TypeOfPlace: this.model.highlights.TypeOfPlace,
          }),
        },
        Cuisine: {
          icon: 'cuisine',
          title: this.$t('dining.cuisine_type', {
            Cuisine: this.model.highlights.Cuisine,
          }),
        },
        Distance: {
          icon: 'distance',
          title: this.$t('dining.distance', {
            Distance: this.model.highlights.Distance,
          }),
        },
        Delivery: {
          icon: 'delivery_car',
          title: this.model.highlights.Delivery,
        },
        TakeAway: {
          icon: 'take_away',
          title: this.model.highlights.TakeAway,
        },
        SafetyMeasures: {
          icon: 'measures',
          title: this.$t('product.menu_names.health_safety_measures'),
        },
      };
    },

    diningHighlights() {
      const highlights = this.hasModel ? this.model.highlights : {};
      const highlightsFormatted = Object.keys(highlights).map((key) => {
        return {
          ...this.highlightsInfo[key],
        };
      });
      const safetyMeasures =
        this.hasModel &&
        this.model.safetyMeasures &&
        this.model.safetyMeasures.length;
      if (safetyMeasures) {
        highlightsFormatted.push({ ...this.highlightsInfo['SafetyMeasures'] });
      }
      return highlightsFormatted;
    },

    description() {
      return this.hasModel ? this.model.description : null;
    },

    operatingHoursList() {
      return this.hasModel ? this.model.operatingHours.formattedText : null;
    },

    activeTabId() {
      let sortedTabs = this.activeBlocks
        .map((item) => ({
          id: item,
          order: this.menuNames.findIndex((menuItem) => menuItem.id === item),
        }))
        .sort((a, b) => a.order - b.order);
      return sortedTabs.length > 0 ? sortedTabs[0] : null;
    },
  },

  methods: {
    triggerDetailsViewAnalytics() {
      this.pushProductDetailsViewAnalytics(
        this.model.id,
        'dining',
        'list',
        'Dining - Product Details'
      );
    },

    scrollToMenu(refName) {
      let element = this.$refs[refName].$el;
      if (!!element) {
        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          (this.$vuetify.breakpoint.mdAndDown ? 150 : 230);
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    },

    setActiveBlock(block) {
      let activeBlocks = [...this.activeBlocks, block];
      this.activeBlocks = Object.assign([], [...new Set(activeBlocks)]);
    },

    removeActiveBlock(block) {
      this.activeBlocks.splice(this.activeBlocks.indexOf(block), 1);
    },

    onIntersect(entries) {
      entries[0].target.classList.forEach((item) => {
        let blockName = this.getBlockName(item);
        if (blockName) {
          this.intersectController(entries[0].isIntersecting, blockName);
        }
      });
    },

    getBlockName(name) {
      switch (name) {
        case 'highlights':
          return `${name}-id`;
        case 'overview':
          return `${name}-id`;
        case 'safetyMeasures':
          return `${name}-id`;
        case 'photos':
          return `${name}-id`;
        case 'location':
          return `${name}-id`;
        default:
          return '';
      }
    },

    intersectController(isIntersecting, blockName) {
      if (isIntersecting) {
        this.setActiveBlock(blockName);
      } else {
        this.removeActiveBlock(blockName);
      }
    },
  },

  watch: {
    model(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.triggerDetailsViewAnalytics();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-details-section {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 260px;
}

::v-deep .v-expansion-panel {
  box-shadow: none;

  &:before {
    box-shadow: none !important;
  }
}

::v-deep .includes {
  li {
    list-style-image: url(/img/icons/check.png);
  }
}

::v-deep .excludes {
  li {
    list-style-image: url(/img/icons/close.png);
  }
}

.reviews-total {
  color: var(--v-grey7-base);
  @include font-size(14, 150, 400);
}

.rating-text {
  color: var(--v-grey7-base);
  @include font-size(14, 150, 600);
}

.page-navigation-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  background: white;
  z-index: 4;

  &.push-top {
    top: 73px;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      top: 164px;
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
      top: 176px;
    }
  }
}

.dining-title {
  @include font-size(24, 150, 400);

  @media (min-width: map-get($grid-breakpoints, md)) {
    @include font-size(36, 150, 400);
  }
}
</style>
