<template>
  <v-container class="safetyMeasures pa-0 pt-4">
    <v-row no-gutters>
      <v-col
        cols="12"
        class="py-0 d-flex"
        :class="{ 'flex-column': $vuetify.breakpoint.lgAndDown }"
      >
        <v-col cols="12" lg="5" class="pb-0 pt-2 pt-md-3">
          <p class="safetyMeasures-title mb-2">
            {{ $t('product.menu_names.health_safety_measures') }}
          </p>
          <p class="safetyMeasures-subtitle">
            {{ $t('product.safety.health_safety_message') }}
          </p>
        </v-col>
        <v-col
          cols="12"
          lg="7"
          tag="ul"
          class="safetyMeasures-list d-flex justify-center pa-0 flex-wrap"
          :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
        >
          <template v-if="!!hasIntensifiedCleaning">
            <v-col
              tag="li"
              class="
                safetyMeasures-item
                d-flex
                text-center
                pa-0
                align-center
                flex-row flex-column
              "
            >
              <!-- don't move svg to v-img because usage of a global variable for color -->
              <svg
                :width="$vuetify.breakpoint.smAndDown ? '60' : '100'"
                :height="$vuetify.breakpoint.smAndDown ? '60' : '100'"
                viewBox="0 0 101 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.8301 52.2501L47.8301 33.4901C48.0363 32.7227 48.5386 32.0685 49.2266 31.6711C49.9147 31.2736 50.7323 31.1654 51.5001 31.3701L82.4101 39.6501C82.7911 39.7521 83.1483 39.9284 83.4612 40.1686C83.774 40.4089 84.0364 40.7085 84.2333 41.0504C84.4302 41.3922 84.5578 41.7695 84.6087 42.1607C84.6597 42.5518 84.6329 42.9492 84.5301 43.3301L78.3501 66.5101C78.248 66.8911 78.0718 67.2483 77.8315 67.5611C77.5912 67.874 77.2916 68.1364 76.9498 68.3333C76.608 68.5302 76.2307 68.6578 75.8395 68.7087C75.4483 68.7596 75.0509 68.7329 74.6701 68.6301L67.0601 66.6301"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M49.2002 54.9001L60.8602 43.2401C61.0924 43.0073 61.3683 42.8226 61.672 42.6965C61.9758 42.5705 62.3014 42.5056 62.6302 42.5056C62.959 42.5056 63.2846 42.5705 63.5884 42.6965C63.8921 42.8226 64.168 43.0073 64.4002 43.2401V43.2401C64.6327 43.4715 64.8172 43.7465 64.9431 44.0494C65.069 44.3523 65.1338 44.6771 65.1338 45.0051C65.1338 45.3331 65.069 45.6579 64.9431 45.9608C64.8172 46.2637 64.6327 46.5387 64.4002 46.7701L52.7302 58.4401"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M54.8496 56.32L66.4996 44.65C66.7318 44.4172 67.0077 44.2325 67.3114 44.1064C67.6152 43.9804 67.9408 43.9155 68.2696 43.9155C68.5984 43.9155 68.924 43.9804 69.2278 44.1064C69.5315 44.2325 69.8074 44.4172 70.0396 44.65V44.65C70.505 45.1216 70.7659 45.7575 70.7659 46.42C70.7659 47.0825 70.505 47.7184 70.0396 48.19L58.3896 59.85"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M56.2695 62L67.9395 50.31C68.1709 50.0775 68.4459 49.893 68.7488 49.7671C69.0517 49.6412 69.3765 49.5764 69.7045 49.5764C70.0325 49.5764 70.3573 49.6412 70.6602 49.7671C70.9631 49.893 71.2382 50.0775 71.4695 50.31V50.31C71.702 50.5414 71.8865 50.8164 72.0124 51.1193C72.1383 51.4222 72.2032 51.747 72.2032 52.075C72.2032 52.4031 72.1383 52.7278 72.0124 53.0307C71.8865 53.3336 71.702 53.6087 71.4695 53.84L59.7995 65.51"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M59.0996 66.2201L67.9396 57.3801C68.171 57.1476 68.446 56.9631 68.7489 56.8372C69.0518 56.7113 69.3766 56.6465 69.7046 56.6465C70.0326 56.6465 70.3574 56.7113 70.6603 56.8372C70.9632 56.9631 71.2382 57.1476 71.4696 57.3801C71.7021 57.6115 71.8866 57.8865 72.0125 58.1894C72.1384 58.4923 72.2032 58.8171 72.2032 59.1451C72.2032 59.4731 72.1384 59.7979 72.0125 60.1008C71.8866 60.4037 71.7021 60.6787 71.4696 60.9101L59.7996 72.5801"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M40.71 54.9001L49.9 45.7101C50.0857 45.5242 50.3063 45.3766 50.5491 45.276C50.7919 45.1753 51.0521 45.1235 51.315 45.1235C51.5778 45.1235 51.838 45.1753 52.0808 45.276C52.3236 45.3766 52.5442 45.5242 52.73 45.7101C53.1019 46.0816 53.3969 46.5228 53.5982 47.0083C53.7995 47.4939 53.9031 48.0144 53.9031 48.5401C53.9031 49.0658 53.7995 49.5863 53.5982 50.0719C53.3969 50.5575 53.1019 50.9986 52.73 51.3701L47.78 56.3701"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M61.2199 71.1701L56.2699 76.1701C55.2484 77.1921 54.0355 78.0028 52.7006 78.5559C51.3657 79.109 49.9349 79.3937 48.4899 79.3937C47.0449 79.3937 45.6141 79.109 44.2792 78.5559C42.9443 78.0028 41.7314 77.1921 40.7099 76.1701L37.8799 73.3401C36.8579 72.3186 36.0472 71.1057 35.4941 69.7708C34.9409 68.4359 34.6562 67.005 34.6562 65.5601C34.6562 64.1151 34.9409 62.6843 35.4941 61.3494C36.0472 60.0144 36.8579 58.8016 37.8799 57.7801L42.8299 52.8301"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M37.9697 28.3501C39.3106 28.8747 40.6024 29.5172 41.8297 30.2701"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M27.8896 26.74C30.0769 26.6274 32.2693 26.8056 34.4096 27.27"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M17.1602 29.8901C19.1948 28.7114 21.3966 27.8483 23.6902 27.3301"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M34.5596 34.24C35.9741 34.5048 37.3627 34.8929 38.7096 35.4"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M24.3604 34.5601C26.4856 34.0352 28.6721 33.7998 30.8604 33.8601"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M14.4102 39.6699C16.1901 38.128 18.1927 36.8638 20.3502 35.9199"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M34.0703 40.3601C35.496 40.5397 36.902 40.851 38.2703 41.2901"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M23.8896 41.2499C25.9824 40.6078 28.1516 40.2479 30.3396 40.1799"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M14.25 46.9101C15.9415 45.2784 17.8658 43.9068 19.96 42.8401"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
              <p class="safetyMeasures-item__title mb-0">
                {{ $t('product.safety.intensified_cleaning') }}
              </p>
            </v-col>
          </template>

          <template v-if="!!hasSocialDistancing">
            <v-col
              tag="li"
              class="
                safetyMeasures-item
                d-flex
                text-center
                pa-0
                align-center
                flex-row flex-md-column
              "
            >
              <!-- don't move svg to v-img because usage of a global variable for color -->
              <svg
                :width="$vuetify.breakpoint.smAndDown ? '60' : '100'"
                :height="$vuetify.breakpoint.smAndDown ? '60' : '100'"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 32C35 33.1867 34.6481 34.3467 33.9888 35.3334C33.3295 36.3201 32.3925 37.0892 31.2961 37.5433C30.1997 37.9974 28.9933 38.1162 27.8295 37.8847C26.6656 37.6532 25.5965 37.0818 24.7574 36.2426C23.9182 35.4035 23.3468 34.3344 23.1153 33.1705C22.8838 32.0067 23.0026 30.8003 23.4567 29.7039C23.9109 28.6075 24.6799 27.6705 25.6666 27.0112C26.6533 26.3519 27.8133 26 29 26C30.5913 26 32.1174 26.6321 33.2426 27.7574C34.3679 28.8826 35 30.4087 35 32Z"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M23 59C21.9391 59 20.9217 58.5786 20.1716 57.8284C19.4214 57.0783 19 56.0609 19 55V46C19 43.8783 19.8429 41.8434 21.3431 40.3431C22.8434 38.8429 24.8783 38 27 38H31C33.1217 38 35.1566 38.8429 36.6569 40.3431C38.1571 41.8434 39 43.8783 39 46V55C39 56.0609 38.5786 57.0783 37.8284 57.8284C37.0783 58.5786 36.0609 59 35 59"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M35 45.5V71.5C35 72.2956 34.6839 73.0587 34.1213 73.6213C33.5587 74.1839 32.7956 74.5 32 74.5H26C25.2044 74.5 24.4413 74.1839 23.8787 73.6213C23.3161 73.0587 23 72.2956 23 71.5V45.5"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M29 74.5V61.5"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M77 32C77 33.1867 76.6481 34.3467 75.9888 35.3334C75.3295 36.3201 74.3925 37.0892 73.2961 37.5433C72.1997 37.9974 70.9933 38.1162 69.8295 37.8847C68.6656 37.6532 67.5965 37.0818 66.7574 36.2426C65.9182 35.4035 65.3468 34.3344 65.1153 33.1705C64.8838 32.0067 65.0026 30.8003 65.4567 29.7039C65.9109 28.6075 66.6799 27.6705 67.6666 27.0112C68.6533 26.3519 69.8133 26 71 26C72.5913 26 74.1174 26.6321 75.2426 27.7574C76.3679 28.8826 77 30.4087 77 32Z"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M65 59C63.9391 59 62.9217 58.5786 62.1716 57.8284C61.4214 57.0783 61 56.0609 61 55V46C61 43.8783 61.8429 41.8434 63.3431 40.3431C64.8434 38.8429 66.8783 38 69 38H73C75.1217 38 77.1566 38.8429 78.6569 40.3431C80.1571 41.8434 81 43.8783 81 46V55C81 56.0609 80.5786 57.0783 79.8284 57.8284C79.0783 58.5786 78.0609 59 77 59"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M77 45.5V71.5C77 72.2956 76.6839 73.0587 76.1213 73.6213C75.5587 74.1839 74.7956 74.5 74 74.5H68C67.2044 74.5 66.4413 74.1839 65.8787 73.6213C65.3161 73.0587 65 72.2956 65 71.5V45.5"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M71 74.5V61.5"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M45 41H54.57"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M49.5703 36L54.5703 41L49.5703 46"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M54.57 59H45"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M50 64L45 59L50 54"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p class="safetyMeasures-item__title mb-0">
                {{ $t('product.safety.social_distancing_enforced') }}
              </p>
            </v-col>
          </template>

          <template v-if="!!faceMask">
            <v-col
              tag="li"
              class="
                safetyMeasures-item
                d-flex
                text-center
                pa-0
                align-center
                flex-row flex-md-column
              "
            >
              <!-- don't move svg to v-img because usage of a global variable for color -->
              <svg
                :width="$vuetify.breakpoint.smAndDown ? '60' : '100'"
                :height="$vuetify.breakpoint.smAndDown ? '60' : '100'"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.9998 38.61C31.9998 38.61 28.3998 29.61 22.9998 32.31C17.5998 35.01 16.8098 45.81 28.7898 59.31"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M65.1297 37.12L55.1797 34C51.8093 32.93 48.1902 32.93 44.8197 34L34.8197 37.16C33.3515 37.631 32.0006 38.4098 30.8572 39.4445C29.7139 40.4792 28.8045 41.7459 28.1897 43.16L26.8697 46.16C26.0082 48.1455 25.891 50.3751 26.5397 52.44L30.2397 64.28C30.7728 65.82 31.6468 67.2198 32.7965 68.3747C33.9463 69.5297 35.3421 70.41 36.8797 70.95L43.7097 73.33C47.7485 74.7502 52.151 74.7502 56.1897 73.33L63.0197 70.95C64.5574 70.41 65.9532 69.5297 67.1029 68.3747C68.2527 67.2198 69.1267 65.82 69.6597 64.28L73.3597 52.44C74.0085 50.3751 73.8913 48.1455 73.0297 46.16L71.7097 43.16C71.105 41.7435 70.2053 40.4722 69.0707 39.4308C67.9361 38.3893 66.5926 37.6015 65.1297 37.12V37.12Z"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M68 38.61C68 38.61 71.6 29.61 77 32.31C82.4 35.01 83.19 45.81 71.21 59.31"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M59.8996 46L57.1896 46.62C52.4584 47.7109 47.5409 47.7109 42.8096 46.62L40.0996 46"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M59.8996 52.29L57.1896 52.91C52.4584 54.001 47.5409 54.001 42.8096 52.91L40.0996 52.29"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M59.8996 58.5901L57.1896 59.2101C52.4584 60.301 47.5409 60.301 42.8096 59.2101L40.0996 58.5901"
                  style="stroke: var(--v-primary-base)"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>
              <p class="safetyMeasures-item__title mb-0">
                {{ $t('product.safety.face_masks') }}
              </p>
            </v-col>
          </template>
        </v-col>
      </v-col>
      <transition name="fade">
        <template v-if="isShowMore">
          <v-col cols="12" class="pl-6 includes">
            <template v-if="faceMask">
              <p>{{ $t('product.safety.face_mark') }}</p>
            </template>

            <template v-if="cashAccepted">
              <p>
                {{ $t('product.safety.cash_accepted') }}
              </p>
            </template>

            <template v-if="gloves">
              <p>{{ $t('product.safety.gloves_available') }}</p>
            </template>

            <template v-if="hasHandSanitizer">
              <p>
                {{ $t('product.safety.has_hand_sanitizer') }}
              </p>
            </template>

            <template v-if="hasIntensifiedCleaning">
              <p>
                {{ $t('product.safety.has_intensified_cleaning') }}
              </p>
            </template>

            <template v-if="hasReducedCapacity">
              <p>
                {{ $t('product.safety.has_reduced_capacity') }}
              </p>
            </template>

            <template v-if="hasSocialDistancing">
              <p>
                {{ $t('product.safety.social_distance_required') }}
              </p>
            </template>

            <template v-if="hasTemperatureChecks">
              <p>
                {{ $t('product.safety.has_temperature_check') }}
              </p>
            </template>

            <template v-if="reducedCapacitySize">
              <p :v-html="reducedCapacitySize" />
            </template>

            <template v-if="onlineTicketingOnly">
              <p>
                {{ $t('product.safety.online_tickets_only') }}
              </p>
            </template>

            <template v-if="limitedDuration">
              <p>
                {{ $t('product.safety.has_limited_duration') }}
              </p>
            </template>

            <template v-if="waitingTime">
              <p>{{ $t('product.safety.has_waiting_time') }}</p>
            </template>

            <template v-if="otherSafetyInfo">
              <p>
                {{ $t('product.safety.has_other_safety_info') }}
              </p>
            </template>
          </v-col>
        </template>
      </transition>
      <transition name="fade">
        <template v-if="isShowMore">
          <v-col cols="12">
            <p class="pl-6 ma-0">
              {{ $t('product.safety.safety_precautions_info') }}
            </p>
          </v-col>
        </template>
      </transition>
      <v-col cols="12" class="px-6 pt-0 pb-7">
        <p
          class="safetyMeasures-toggleButton"
          :class="[$vuetify.breakpoint.lgAndDown ? 'ma-0 mt-5' : 'ma-0']"
          @click="toggle()"
        >
          {{ showMoreLessText }}
        </p>
      </v-col>
      <div class="pt-sm-10 pt-6">
        <v-divider />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import ProductDetailsSafetyMeasuresMixins from '@/modules/product/booking/mixins/ProductDetailsSafetyMeasuresMixins';

export default {
  name: 'DDHealthSafety',
  mixins: [ProductDetailsSafetyMeasuresMixins],
  props: {
    hasModel: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isShowMore: false,
    };
  },

  methods: {
    toggle() {
      this.isShowMore = !this.isShowMore;
    },
  },

  computed: {
    showMoreLessText() {
      return this.isShowMore ? this.$t('show_less') : this.$t('show_details');
    },
  },
};
</script>

<style scoped lang="scss">
.safetyMeasures {
  background: var(--v-grey1-base);
  border-radius: 4px;
  border: 1px solid var(--v-grey3-base);
  margin: 0 0 60px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin: 0 0 80px;
  }

  &-title {
    color: var(--v-grey9-base);
    @include font-size(18, 150, 600);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      @include font-size(24, 150, 600);
    }
  }

  &-subtitle {
    @include font-size(14, 150);
    color: var(--v-grey8-base);
  }

  &-list {
    list-style: none;
  }

  &-item {
    max-width: 100%;

    @media (min-width: map-get($grid-breakpoints, md)) {
      max-width: 120px;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
      margin: 0 12.5px;
    }

    &__title {
      @include font-size(14, 150, 600);
      color: #05090f;
    }
  }

  &-toggleButton {
    color: var(--v-primary-base);
    @include font-size(14, 150, 600);
    cursor: pointer;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      margin-right: 25px;
    }
  }
}

// style for animation more/less details
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

// end
</style>
