import {
  DINING_DETAILS_LOADED,
  DINING_DETAILS_LOADING,
  DINING_DETAILS_ERROR,
} from './mutation-types';

export const mutations = {
  [DINING_DETAILS_LOADED](state, { product } = { product: {} }) {
    state.diningDetails = { ...state.diningDetails, value: { ...product } };
  },
  [DINING_DETAILS_LOADING](state, payload = true) {
    state.diningDetails = { ...state.diningDetails, isLoading: payload };
  },
  [DINING_DETAILS_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.diningDetails = {
      ...state.diningDetails,
      value: null,
      error: error,
    };
  },
};
