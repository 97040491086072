<template>
  <ErrorPage>
    <template v-slot:header>
      <p
        :class="[
          'dnf-title',
          $vuetify.breakpoint.mdAndDown
            ? 'dnf-title-mobile'
            : 'dnf-title-desktop',
        ]"
      >
        {{ $t('exception.product_not_found') }}
      </p>
      <p
        :class="
          $vuetify.breakpoint.mdAndDown ? 'dnf-text-mobile' : 'dnf-text-desktop'
        "
      >
        {{ $t('exception.unavailable_product') }}
      </p>
      <p
        :class="
          $vuetify.breakpoint.mdAndDown ? 'dnf-text-mobile' : 'dnf-text-desktop'
        "
      >
        {{ $t('exception.go_back') }}
      </p>
    </template>

    <template v-slot:footer>
      <v-btn
        v-if="!shopOnlyMode"
        depressed
        color="primary"
        class="text-capitalize dnf-gotohome"
        @click="goToHomePage()"
      >
        {{ $t('exception.go_to_homepage') }}
      </v-btn>
    </template>
  </ErrorPage>
</template>

<script>
import ErrorPage from '@/modules/exception/ErrorPage';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'ProductNotFound',
  components: { ErrorPage },
  mixins: [ConfigsMixins],
  props: {
    productName: String,
  },
  methods: {
    goToHomePage() {
      this.$router.push({ name: this.productName });
    },
  },
};
</script>

<style lang="scss" scoped>
/* dnf - DetailNotFound */
.dnf-title {
  color: #000000;
  margin-bottom: 40px;

  &-mobile {
    @include font-size(24, 33, 600);
  }

  &-desktop {
    @include font-size(48, 65, 600);
  }
}

.dnf-text-mobile,
.dnf-text-desktop {
  color: #000000;
  max-width: 576px;
  margin: 0 auto 20px;
}

.dnf-text {
  &-mobile {
    @include font-size(16, 24, 400);
  }

  &-desktop {
    @include font-size(18, 27, 400);
  }
}

::v-deep .dnf-gotohome {
  position: relative;
  top: -60px;

  > span {
    line-height: 1.2;
  }
}
</style>
