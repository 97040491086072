import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';

export const actions = {
  async loadDetails({ commit }, { productId, latitude, longitude }) {
    const diningService = RepoFactory.get('place', {
      resourceId: productId,
    });
    commit(mutations.DINING_DETAILS_ERROR, { error: null });
    commit(mutations.DINING_DETAILS_LOADING, true);
    try {
      const { data } = await diningService.getDetails({
        latitude,
        longitude,
      });
      commit(mutations.DINING_DETAILS_LOADED, { product: data });
    } catch (error) {
      let usefulError = {
        message: error.toString(),
        code: 500,
      };

      if (!!error.response && !!error.response.data) {
        usefulError.message = error.response.data.errorMessage;
        usefulError.code = error.response.data.errorCode;
      }

      commit(mutations.DINING_DETAILS_ERROR, { error: usefulError });
    } finally {
      commit(mutations.DINING_DETAILS_LOADING, false);
    }
  },
};
