<template>
  <v-container class="pa-0 highlights">
    <v-row no-gutters>
      <v-col cols="12" class="pt-sm-2 pt-1 mb-5">
        <DDSectionTitle title="Highlights" />
      </v-col>
      <v-col cols="12" class="highlights-list d-flex flex-wrap">
        <v-col cols="12" sm="6" tag="ul" class="pa-0 d-flex flex-column">
          <li
            class="highlights-item pb-4 d-flex align-center"
            v-for="item in firstColumn"
            :key="item.icon"
          >
            <simple-svg
              width="20"
              height="20"
              :src="require(`@/assets/highlights/${item.icon}.svg`)"
            />
            <span class="ml-3">
              {{ item.title }}
            </span>
          </li>
        </v-col>

        <template v-if="lastColumn.length">
          <v-col cols="12" sm="6" tag="ul" class="pa-0 d-flex flex-column">
            <li
              class="highlights-item pb-4 d-flex align-center"
              v-for="item in lastColumn"
              :key="item.icon"
            >
              <simple-svg
                width="20"
                height="20"
                :src="require(`@/assets/highlights/${item.icon}.svg`)"
              />
              <span class="ml-3">
                {{ item.title }}
              </span>
            </li>
          </v-col>
        </template>
      </v-col>
      <v-col cols="12" class="py-sm-6 pt-2 pb-4">
        <v-divider />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DDSectionTitle from './DDSectionTitle';

export default {
  name: 'DDHighlights',
  components: { DDSectionTitle },
  props: {
    highlights: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    firstColumn() {
      return this.highlights.slice(0, 4);
    },

    lastColumn() {
      return this.highlights.slice(4);
    },
  },
};
</script>

<style scoped lang="scss">
.highlights {
  &-list {
    list-style: none;
  }

  &-item {
    color: var(--v-grey8-base);
    @include font-size(16, 150);
  }

  &-text {
    color: var(--v-grey8-base);
    @include font-size(16, 150);
  }
}
</style>
