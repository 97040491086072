import { render, staticRenderFns } from "./DDRating.vue?vue&type=template&id=2ff7ec81&"
import script from "./DDRating.vue?vue&type=script&lang=js&"
export * from "./DDRating.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports