<template>
  <v-sheet class="options-cta-card">
    <v-container class="pa-0">
      <v-row class="pa-6 pl-5">
        <v-col cols="12" class="pt-0 pb-4" v-if="!!host">
          <v-row no-gutters>
            <v-col cols="1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  :stroke="$vuetify.theme.themes.light.grey7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 12H22"
                  :stroke="$vuetify.theme.themes.light.grey7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                  :stroke="$vuetify.theme.themes.light.grey7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-col>
            <v-col cols="11" class="px-2">
              <template v-if="!loading">
                <a
                  :href="host.href"
                  title="link to site"
                  target="_blank"
                  tabindex="0"
                >
                  {{ host.hostname }}
                </a>
              </template>
              <template v-else>
                <v-skeleton-loader max-width="150" type="text" />
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pt-0 pb-4" v-if="phoneNumber">
          <v-row no-gutters>
            <v-col cols="1">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 3C1.5 2.46957 1.71071 1.96086 2.08579 1.58579C2.46086 1.21071 2.96957 1 3.5 1H6.78C6.98979 1.00016 7.19422 1.0663 7.36436 1.18905C7.53449 1.3118 7.6617 1.48496 7.728 1.684L9.226 6.177C9.3019 6.40534 9.29293 6.65339 9.20072 6.87564C9.10851 7.0979 8.93925 7.27945 8.724 7.387L6.467 8.517C7.57332 10.9655 9.53446 12.9267 11.983 14.033L13.113 11.776C13.2205 11.5607 13.4021 11.3915 13.6244 11.2993C13.8466 11.2071 14.0947 11.1981 14.323 11.274L18.816 12.772C19.0152 12.8383 19.1885 12.9657 19.3112 13.136C19.434 13.3064 19.5001 13.511 19.5 13.721V17C19.5 17.5304 19.2893 18.0391 18.9142 18.4142C18.5391 18.7893 18.0304 19 17.5 19H16.5C8.216 19 1.5 12.284 1.5 4V3Z"
                  :stroke="$vuetify.theme.themes.light.grey7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-col>
            <template v-if="!loading">
              <v-col cols="11" class="px-2">
                <a
                  class="options-cta-card-phone"
                  :href="`tel:${phoneNumber}`"
                  title="phone number"
                >
                  {{ phoneNumber }}
                </a>
              </v-col>
            </template>

            <template v-else>
              <v-col cols="11" class="pa-2">
                <v-skeleton-loader width="150" type="text" />
              </v-col>
            </template>
          </v-row>
        </v-col>
        <v-col cols="12" class="py-0 size16-weight400" v-if="address">
          <v-row no-gutters>
            <v-col cols="1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 11C12.2652 11 12.5196 10.8946 12.7071 10.7071C12.8946 10.5196 13 10.2652 13 10C13 9.73478 12.8946 9.48043 12.7071 9.29289C12.5196 9.10536 12.2652 9 12 9C11.7348 9 11.4804 9.10536 11.2929 9.29289C11.1054 9.48043 11 9.73478 11 10C11 10.2652 11.1054 10.5196 11.2929 10.7071C11.4804 10.8946 11.7348 11 12 11ZM14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213Z"
                  :fill="$vuetify.theme.themes.light.grey7"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.05334 15.3953L7.05547 15.3975L12 20.5079L16.9467 15.3953C17.9265 14.3857 18.5941 13.0989 18.8649 11.6977C19.1357 10.2965 18.9975 8.84394 18.468 7.52377C17.9384 6.2036 17.0412 5.07514 15.8899 4.28118C14.7386 3.48722 13.3848 3.06343 12 3.06343C10.6152 3.06343 9.26144 3.48722 8.11011 4.28118C6.95877 5.07514 6.06157 6.2036 5.53201 7.52377C5.00246 8.84394 4.86434 10.2965 5.13514 11.6977C5.40595 13.0989 6.0735 14.3857 7.05334 15.3953ZM5.64 16.8553C4.38021 15.5572 3.52193 13.9027 3.17376 12.1012C2.82558 10.2997 3.00316 8.43209 3.68402 6.73472C4.36488 5.03736 5.51842 3.58649 6.99871 2.56568C8.47899 1.54487 10.2195 1 12 1C13.7805 1 15.521 1.54487 17.0013 2.56568C18.4816 3.58649 19.6351 5.03736 20.316 6.73472C20.9968 8.43209 21.1744 10.2997 20.8262 12.1012C20.4781 13.9027 19.6198 15.5572 18.36 16.8553L12.71 22.6948C12.617 22.7915 12.5064 22.8683 12.3846 22.9207C12.2627 22.973 12.132 23 12 23C11.868 23 11.7373 22.973 11.6154 22.9207C11.4936 22.8683 11.383 22.7915 11.29 22.6948L5.64 16.8553Z"
                  :fill="$vuetify.theme.themes.light.grey7"
                />
              </svg>
            </v-col>

            <v-col cols="11" class="px-2">
              <template v-if="!loading">
                {{ address }}
              </template>
              <template v-else>
                <v-skeleton-loader width="150" type="text" />
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      type="button"
      :disabled="loading || !enableCta"
      color="primary"
      block
      tile
      data-cy="visit-place-option"
      height="54px"
      class="pt-1 size18-weight600 button-text"
      @click="triggerButtonClickAnalytics()"
      tabindex="0"
    >
      {{ buttonText }}
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="ml-2"
      >
        <path
          d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 2.5H17.5V7.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.33331 11.6667L17.5 2.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </v-btn>
  </v-sheet>
</template>

<script>
import DiningDetailsMixins from '@/modules/product/dining/mixins/DiningDetailsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'options-dining-cta-card',
  mixins: [DiningDetailsMixins, GoogleTagManagerMixins],
  props: {
    loading: {
      default: true,
      type: Boolean,
    },
    websiteUrl: {
      type: String,
      default: null,
    },
    phoneNumber: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    buttonText: {
      default: function() {
        return this.$t('dining.visit_the_website');
      },
      type: String,
    },
    enableCta: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    host() {
      if (!this.websiteUrl) return null;
      return new URL(this.websiteUrl);
    },
  },
  methods: {
    triggerButtonClickAnalytics() {
      window.open(this.websiteUrl, '_blank');
      this.pushCheckoutAnalytics(this.productId, 1, 'dining');
      this.pushDiningVisitWebsiteAnalytics(this.productId);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.options {
  &-cta-card {
    margin-top: 10px;
    margin-left: auto;
    width: 100%;
    max-width: 100%;
    background: transparent;
    border-radius: 8px;
    overflow: hidden;
    border-color: #e8e6e1;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);

    @media (min-width: map-get($grid-breakpoints, md)) {
      max-width: 360px;
    }
  }
}
.button-text {
  letter-spacing: 0;
}
</style>
