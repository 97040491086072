<script>
import DiningStoreModule from '../store/index.js';
export default {
  name: 'DiningProductMixins',
  computed: {
    productId() {
      return this.$route.params.id;
    },
    storeModuleName() {
      return !!this.productId ? `dining/${this.productId}` : 'dining';
    },
  },
  methods: {
    registerStore() {
      const store = this.$store;
      if (!DiningStoreModule[`registered-${this.storeModuleName}`]) {
        store.registerModule(this.storeModuleName, DiningStoreModule, {
          preserveState: !!store.state[this.storeModuleName],
        });
        DiningStoreModule[`registered-${this.storeModuleName}`] = true;
      }
    },
  },
};
</script>
